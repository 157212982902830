<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="customFields"
                          columnsPrefix="custom_fields.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          title="custom_fields.title.custom_fields"
                          :actions-list="getActions()"
                >
                    <template #cell(actions)="row">
                        <b-button
                            @click="$root.$children[0].openModal('custom-field-modal', {id:row.item.id},refreshTable)"
                            size="sm"
                            variant="info"
                            class="mr-2"
                            :title="$t('common.title.edit')"
                        >
                            <font-awesome-icon icon="edit"/>
                        </b-button>

                        <b-button @click="deleteCustomField(row.item.id)"
                                  size="sm"
                                  variant="danger"
                                  :title="$t('common.title.delete')"
                        >
                            <font-awesome-icon icon="trash"/>
                        </b-button>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'CustomFields',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: true,
        }
    },
    mounted() {
        if (this.$route.query.name && this.$route.query.model) {
            this.$root.$children[0].openModal('custom-field-modal', {
                name: this.$route.query.name,
                model: this.$route.query.model
            }, this.refreshTable)
        }
        if (this.$route.query.id) {
            this.$root.$children[0].openModal('custom-field-modal', {id: this.$route.query.id}, this.refreshTable)
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getSettings']),
        ...mapGetters('CustomFields', ['getFieldsTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'CustomFields/fetchFields', 'getFieldsTable');
        },
        deleteCustomField(id) {
            this.$removeConfirm('CustomFields/deleteField', id, this.refreshTable)
        },
        getActions() {
            return [
                {
                    title: this.$t('common.button.create'),
                    icon: 'plus',
                    click: () => {
                        this.$root.$children[0].openModal('custom-field-modal', {id: 0}, this.refreshTable)
                    }
                },
            ]
        }
    },
}
</script>